/**
 * This is the main application.
 */
import * as React from 'react';
import { LanguageContextProvider, TranslationContextProvider, ILanguageDefinition, TranslationEntryBuilder } from '@kuchenmeister/modules-react-translation';
import { RootPage } from './pages/RootPage';
import { store } from './store';
import './styles/Training.scss';
import { GlobalEventStoreProvider } from '@kuchenmeister/modules-react-effectstore';
import { _Example } from './pages/_example';

export interface IProps extends React.Props<any> {

}

//const theme = document.head.appendChild<HTMLStyleElement>(document.createElement('style'));
//theme.innerHTML = `/* The current theme */
//:root { 
//    --km-btn-bg-color:      #efefef;
//    --km-btn-border-color:  #0a0a0a;
//    --km-btn-color:         #000000;
//}`

//document.body.style.setProperty('--km-btn-bg-color', '#efefef');
//document.body.style.setProperty('--km-btn-border-color', '#0a0a0a');

declare global {
    type ITranslationKeys = { [key in keyof typeof translations['entries']]: any };
    interface TranslationKeys extends ITranslationKeys { }
    interface TranslationLanguages {
        'pl': 'Polska',
        'ita': 'Italian',
        'bul': 'Bulgarian',
        'ron': 'Romanian',
    }
}

const allLanguages: Array<ILanguageDefinition> = [
    { value: 'de', description: 'Deutsch | German', descriptionEn: 'German' },
    { value: 'en', description: 'English | English', descriptionEn: 'English' },
    { value: 'pl', description: 'Polska | Polish', descriptionEn: 'Polish' },
    { value: 'ita', description: 'Italiano | Italian', descriptionEn: 'Italian' },
    { value: 'bul', description: 'Ð±ÑÐ»Ð³Ð°ÑÑÐºÐ¸ | Bulgarian', descriptionEn: 'Bulgarian' },
    { value: 'ron', description: 'RomÃ¢nÄ | Romania', descriptionEn: 'Romania' },
    { value: 'dan', description: 'Dansk | Danish', descriptionEn: 'Dansk' },
]

// TODO: ordentliche SÃ¤tze!
// Translations for the whole Page
const translations = TranslationEntryBuilder({
    // #region General

    'logoff': {
        'de': 'Abmelden',
        'en': 'Log out',
        'pl': 'Wyloguj siÄ',
        'ita': 'Disconnettersi',
        'bul': 'ÐÐ·Ð»ÐµÐ· Ð¾Ñ Ð¿ÑÐ¾ÑÐ¸Ð»Ð° ÑÐ¸',
        'ron': 'DeconectaÈi- vÄ',
        'dan': 'Log af',
    },

    // #endregion

    // #region Navigate

    'navigate.toHomepage': {
        'de': 'ZurÃ¼ck zur Hauptseite',
        'en': 'Back to the home page',
        'pl': 'PowrÃ³t do strony gÅÃ³wnej',
        'ita': 'Torna alla pagina principale',
        'bul': 'ÐÐ±ÑÐ°ÑÐ½Ð¾ ÐºÑÐ¼ Ð¾ÑÐ½Ð¾Ð²Ð½Ð°ÑÐ° ÑÑÑÐ°Ð½Ð¸ÑÐ°',
        'ron': 'Ãnapoi la pagina principalÄ',
        'dan': 'Tilbage til startsiden',
    },
    'navigate.toContent': {
        'de': 'Zum Inhalt',
        'en': 'To content',
        'pl': 'Do treÅci',
        'ita': 'Al contenuto',
        'bul': 'ÐÑÐ¼ ÑÑÐ´ÑÑÐ¶Ð°Ð½Ð¸ÐµÑÐ¾',
        'ron': 'La conÈinut',
        'dan': 'GÃ¥ til indhold',
    },
    'navigate.toLogin': {
        'de': 'Zur Anmeldung',
        'en': 'To login',
        'pl': 'Do rejestracji',
        'ita': 'Alla registrazione',
        'bul': 'ÐÐ° ÑÐµÐ³Ð¸ÑÑÑÐ°ÑÐ¸ÑÑÐ°',
        'ron': 'La Ã®nregistrare',
        'dan': 'Til registrering',
    },

    // #endregion

    // #region Error messages

    'error.missingLoginData': {
        'de': 'Bitte geben Sie Ihre Anmeldedaten an.',
        'en': 'Please enter your login details.',
        'pl': 'WprowadÅº swoje dane logowania.',
        'ita': 'Inserire i dati di accesso.',
        'bul': 'ÐÐ¾Ð»Ñ, Ð²ÑÐ²ÐµÐ´ÐµÑÐµ Ð´Ð°Ð½Ð½Ð¸ÑÐµ ÑÐ¸ Ð·Ð° Ð²ÑÐ¾Ð´.',
        'ron': 'VÄ rugÄm sÄ introduceÈi datele dumneavoastrÄ de conectare.',
        'dan': 'Indtast venligst dine login oplysninger.',

    },
    'error.pageNotFound': {
        'de': 'Die konnte Seite nicht gefunden werden.',
        'en': 'The page could not be found.',
        'pl': 'Nie moÅ¼na znaleÅºÄ tej strony.',
        'ita': 'La pagina non Ã¨ stata trovata.',
        'bul': 'Ð¡ÑÑÐ°Ð½Ð¸ÑÐ°ÑÐ° Ð½Ðµ Ð¼Ð¾Ð¶Ðµ Ð´Ð° Ð±ÑÐ´Ðµ Ð½Ð°Ð¼ÐµÑÐµÐ½Ð°.',
        'ron': 'Pagina nu a putut fi gÄsitÄ.',
        'dan': 'Siden kunne ikke findes.',
    },
    'error.loginFailed': {
        'de': 'Die Anmeldung ist fehlgeschlagen.',
        'en': 'The login failed.',
        'pl': 'Rejestracja nie powiodÅa siÄ',
        'ita': 'La registrazione Ã¨ fallita.',
        'bul': 'Ð ÐµÐ³Ð¸ÑÑÑÐ°ÑÐ¸ÑÑÐ° Ðµ Ð½ÐµÑÑÐ¿ÐµÑÐ½Ð°.',
        'ron': 'Ãnregistrarea a eÈuat.',
        'dan': 'Login mislykkedes.',
    },
    'error.submitFailed': {
        'de': 'Der Test konnte nicht abgeschlossen werden.',
        'en': 'Test could not be completed.',
        'pl': 'Test nie mÃ³gÅ zostaÄ ukoÅczony.',
        'ita': 'Non Ã¨ stato possibile completare il test.',
        'bul': 'Ð¢ÐµÑÑÑÑ Ð½Ðµ Ð¼Ð¾Ð¶Ðµ Ð´Ð° Ð±ÑÐ´Ðµ Ð·Ð°Ð²ÑÑÑÐµÐ½.',
        'ron': 'Testul nu a putut fi finalizat.',
        'dan': 'Testen kunne ikke gennemfÃ¸res.',
    },
    'error.trainingOrEmployeeKeyInvalid': {
        'de': 'Die Anmeldedaten sind ungÃ¼ltig.',
        'en': 'Employeee key is invalid',
        'pl': 'Dane logowania sÄ nieprawidÅowe.',
        'ita': 'I dati di accesso non sono validi.',
        'bul': 'ÐÐ°Ð½Ð½Ð¸ÑÐµ Ð·Ð° Ð²ÑÐ¾Ð´ ÑÐ° Ð½ÐµÐ²Ð°Ð»Ð¸Ð´Ð½Ð¸.',
        'ron': 'Datele de conectare nu sunt valide.',
        'dan': 'Login oplysningerne er ugyldige.',
    },
    'error.saveResults': {
        'de': 'Speichern Sie ihre Ergebnisse',
        'en': 'Save your results',
        'pl': 'Zapisz wyniki',
        'ita': 'Salvare i risultati',
        'bul': 'ÐÐ°Ð¿Ð°Ð·Ð²Ð°Ð½Ðµ Ð½Ð° ÑÐµÐ·ÑÐ»ÑÐ°ÑÐ¸ÑÐµ',
        'ron': 'SalveazÄ-Èi rezultatele',
        'dan': 'Gem dine resultater',
    },
    'error.trainingNotFound': {
        'de': 'Es wurde kein Training gefunden',
        'en': 'Training not found',
        'pl': 'Nie znaleziono szkoleÅ',
        'ita': 'Nessuna formazione trovata',
        'bul': 'ÐÐµ Ðµ Ð½Ð°Ð¼ÐµÑÐµÐ½Ð¾ Ð¾Ð±ÑÑÐµÐ½Ð¸Ðµ',
        'ron': 'Nu s-a gÄsit nicio formare',
        'dan': 'Ingen trÃ¦ning blev fundet',
    },
    'error.expiredSession': {
        'de': 'Die Sitzung ist abgelaufen',
        'en': 'The session is expired',
        'pl': 'Sesja wygasÅa',
        'ita': 'La sessione Ã¨ scaduta',
        'bul': 'Ð¡ÐµÑÐ¸ÑÑÐ° Ðµ Ð¸Ð·ÑÐµÐºÐ»Ð°',
        'ron': 'Sesiunea a expirat',
        'dan': 'Sessionen er udlÃ¸bet',
    },
    'error.invalidSession': {
        'de': 'UngÃ¼ltige Sitzung',
        'en': 'Invalid session',
        'pl': 'NieprawidÅowa sesja',
        'ita': 'Sessione non valida',
        'bul': 'ÐÐµÐ²Ð°Ð»Ð¸Ð´Ð½Ð° ÑÐµÑÐ¸Ñ',
        'ron': 'Sesiune invalidÄ',
        'dan': 'Ugyldig session',
    },
    'error.endedSession': {
        'de': 'Die Sitzung wurde beendet',
        'en': 'The session ended',
        'pl': 'Spotkanie zostaÅo zamkniÄte',
        'ita': 'La riunione Ã¨ stata chiusa',
        'bul': 'ÐÐ°ÑÐµÐ´Ð°Ð½Ð¸ÐµÑÐ¾ Ð±ÐµÑÐµ Ð·Ð°ÐºÑÐ¸ÑÐ¾',
        'ron': 'ÈedinÈa a fost Ã®nchisÄ.',
        'dan': 'Sessionen er afsluttet',
    },

    // #endregion

    // #region Login page

    'loginPage.registration': {
        'de': 'Anmeldung zur Onlineunterweisung',
        'en': 'Login for online instruction',
        'pl': 'Rejestracja na instrukcja online',
        'ita': 'Accedi per istruzioni online',
        'bul': 'ÐÐ»ÐµÐ·ÑÐµ Ð·Ð° Ð¾Ð½Ð»Ð°Ð¹Ð½ Ð¸Ð½ÑÑÑÑÐºÑÐ¸Ð¸',
        'ron': 'ConectaÈi-vÄ pentru instrucÈiuni online',
        'dan': 'Tilmelding til online undervisning',
    },
    'loginPage.name': {
        'de': 'Vorname',
        'en': 'Name',
        'pl': 'ImiÄ',
        'ita': 'Nome',
        'bul': 'Ð¸Ð¼Ðµ',
        'ron': 'Nume',
        'dan': 'Fornavn',
    },
    'loginPage.surname': {
        'de': 'Nachname',
        'en': 'Surname',
        'pl': 'Nazwisko',
        'ita': 'Cognome',
        'bul': 'Ð¤Ð°Ð¼Ð¸Ð»Ð¸Ñ',
        'ron': 'Nume de familie',
        'dan': 'Efternavn',
    },
    'loginPage.shared': {
        'de': 'Schulungspasswort',
        'en': 'Password for training',
        'pl': 'HasÅo szkolenia',
        'ita': 'Password di allenamento',
        'bul': 'ÐÐ°ÑÐ¾Ð»Ð° Ð·Ð° Ð¾Ð±ÑÑÐµÐ½Ð¸Ðµ',
        'ron': 'Parola de antrenament',
        'dan': 'TrÃ¦ningsadgangskode',
    },
    'loginPage.language': {
        'de': 'Sprache',
        'en': 'Language',
        'pl': 'JÄzyk',
        'ita': 'Lingua',
        'bul': 'ÐµÐ·Ð¸Ðº',
        'ron': 'Limba',
        'dan': 'Sprog',
    },
    'loginPage.employeeKey': {
        'de': 'Personalnummer',
        'en': 'Employee key',
        'pl': 'Numer Personelu',
        'ita': 'Chiave del dipendente',
        'bul': 'ÐÐ»ÑÑ Ð½Ð° ÑÐ»ÑÐ¶Ð¸ÑÐµÐ»',
        'ron': 'Cheia angajatului',
        'dan': 'Personalenummer',
    },
    'loginPage.commit': {
        'de': 'ÃberprÃ¼fen',
        'en': 'Check',
        'pl': 'SprawdÅº',
        'ita': 'Controllo',
        'bul': 'ÐÑÐ¾Ð²ÐµÑÐµÑÐµ',
        'ron': 'Verifica',
        'dan': 'Tjekke over',
    },
    'loginPage.performing': {
        'de': 'Anmeldedaten werden Ã¼berprÃ¼ft',
        'en': 'Registration data will be checked',
        'pl': 'PoÅwiadczenia sÄ weryfikowane',
        'ita': 'I dati di registrazione verranno controllati',
        'bul': 'ÐÐ°Ð½Ð½Ð¸ÑÐµ Ð·Ð° ÑÐµÐ³Ð¸ÑÑÑÐ°ÑÐ¸Ñ ÑÐµ Ð±ÑÐ´Ð°Ñ Ð¿ÑÐ¾Ð²ÐµÑÐµÐ½Ð¸',
        'ron': 'Datele de Ã®nregistrare vor fi verificate',
        'dan': 'Legitimationsoplysninger er verificeret',
    },
    'loginPage.sessionContinue': {
        'de': 'Die Sitzung wird fortgesetzt',
        'en': 'The session will be continued',
        'pl': 'Sesja bÄdzie kontynuowana',
        'ita': 'La sessione proseguirÃ ',
        'bul': 'Ð¡ÐµÑÐ¸ÑÑÐ° ÑÐµ Ð±ÑÐ´Ðµ Ð¿ÑÐ¾Ð´ÑÐ»Ð¶ÐµÐ½Ð°',
        'ron': 'Sesiunea va fi continuatÄ',
        'dan': 'Sessionen fortsÃ¦tter',
    },

    // #endregion

    // #region Content page

    'contentPage.onlineInstruction': {
        'de': 'Onlineunterweisung',
        'en': 'Online instruction',
        'pl': 'InstruktaÅ¼ online',
        'ita': 'Istruzione online',
        'bul': 'ÐÐ½Ð»Ð°Ð¹Ð½ Ð¸Ð½ÑÑÑÑÐºÑÐ¸Ñ',
        'ron': 'Instruire online',
        'dan': 'Online instruktion',
    },
    'contentPage.welcome': {
        'de': 'Willkommen zur Onlineunterweisung {0} {1},',
        'en': 'Welcome to the online instruction {0} {1},',
        'pl': 'Witamy w internetowej instrukcji {0} {1},',
        'ita': 'Benvenuti all\'istruzione online {0} {1},',
        'bul': 'ÐÐ¾Ð±ÑÐµ Ð´Ð¾ÑÐ»Ð¸ Ð² Ð¾Ð½Ð»Ð°Ð¹Ð½ Ð¸Ð½ÑÑÑÑÐºÑÐ¸ÑÑÐ° {0} {1},',
        'ron': 'Bine aÈi venit la instructajul online {0} {1},',
        'dan': 'Velkommen til online instruktion {0} {1},',
    },
    'contentPage.documentLink': {
        'de': 'Link',
        'en': 'Link',
        'pl': 'PoÅÄczyÄ',
        'ita': 'Collegamento',
        'bul': 'ÐÑÑÐ·ÐºÐ°',
        'ron': 'LegÄturÄ',
        'dan': 'Link',
    },
    'contentPage.documentDescription': {
        'de': 'Beschreibung',
        'en': 'Description',
        'pl': 'Opis',
        'ita': 'Descrizione',
        'bul': 'ÐÐ¿Ð¸ÑÐ°Ð½Ð¸Ðµ',
        'ron': 'Descriere',
        'dan': 'Beskrivelse',
    },
    'contentPage.noDocuments': {
        'de': 'Es sind keine Unterlagen vorhanden.',
        'en': 'There are no documents available.',
        'pl': 'Brak dostÄpnych dokumentÃ³w.',
        'ita': 'Non ci sono documenti disponibili.',
        'bul': 'ÐÑÐ¼Ð° Ð½Ð°Ð»Ð¸ÑÐ½Ð¸ Ð´Ð¾ÐºÑÐ¼ÐµÐ½ÑÐ¸.',
        'ron': 'Nu existÄ documente disponibile.',
        'dan': 'Der er ingen dokumenter.',
    },
    'contentPage.startTest': {
        'de': 'Test starten',
        'en': 'Start test',
        'pl': 'Rozpocznij test',
        'ita': 'Inizia la prova',
        'bul': 'ÐÐ°Ð¿Ð¾ÑÐ½ÐµÑÐµ ÑÐµÑÑÐ°',
        'ron': 'ÃncepeÈi testul',
        'dan': 'Start testen',
    },

    // #endregion

    // #region Test page

    'testPage.headline': {
        'de': 'Die Fragen finden Sie in den Dokumenten. Bitte beantworten Sie alle Fragen. Es ist immer nur eine Antwort richtig.',
        'en': 'You can find the questions in the documents. Please answer all questions. Only one answer is correct at a time.',
        'pl': 'Pytania znajdujÄ siÄ w dokumentach. ProszÄ odpowiedzieÄ na wszystkie pytania. W danym momencie tylko jedna odpowiedÅº jest prawidÅowa.',
        'ita': 'Le domande sono riportate nei documenti. Rispondere a tutte le domande. Solo una risposta alla volta Ã¨ corretta.',
        'bul': 'ÐÑÐ¿ÑÐ¾ÑÐ¸ÑÐµ ÑÐµ Ð½Ð°Ð¼ÐµÑÐ¸ÑÐµ Ð² Ð´Ð¾ÐºÑÐ¼ÐµÐ½ÑÐ¸ÑÐµ. ÐÐ¾Ð»Ñ, Ð¾ÑÐ³Ð¾Ð²Ð¾ÑÐµÑÐµ Ð½Ð° Ð²ÑÐ¸ÑÐºÐ¸ Ð²ÑÐ¿ÑÐ¾ÑÐ¸. Ð¡Ð°Ð¼Ð¾ ÐµÐ´Ð¸Ð½ Ð¾ÑÐ³Ð¾Ð²Ð¾Ñ Ðµ Ð²ÐµÑÐµÐ½ Ð² Ð´Ð°Ð´ÐµÐ½ Ð¼Ð¾Ð¼ÐµÐ½Ñ.',
        'ron': 'VeÈi gÄsi Ã®ntrebÄrile Ã®n documente. VÄ rugÄm sÄ rÄspundeÈi la toate Ã®ntrebÄrile. Doar un singur rÄspuns este corect la un moment dat',
        'dan': 'Du kan finde spÃ¸rgsmÃ¥lene i dokumenterne. Svar venligst pÃ¥ alle spÃ¸rgsmÃ¥l. Der er altid kun Ã©t rigtigt svar.',
    },
    'testPage.noQuestions': {
        'de': 'Es sind keine Fragen vorhanden.',
        'en': 'There are no Questions available.',
        'pl': 'Nie ma Å¼adnych pytaÅ.',
        'ita': 'Non ci sono domande',
        'bul': 'ÐÑÐ¼Ð° Ð½Ð°Ð»Ð¸ÑÐ½Ð¸ Ð²ÑÐ¿ÑÐ¾ÑÐ¸',
        'ron': 'Nu sunt Ã®ntrebÄri.',
        'dan': 'Der er ingen spÃ¸rgsmÃ¥l.',
    },
    'testPage.dataTrackingError': {
        'de': 'Sie mÃ¼ssen dem Datenschutz Text zustimmen.',
        'en': 'You need to agree the Terms for tracking data.',
        'pl': 'UÅ¼ytkownik musi wyraziÄ zgodÄ na tekst dotyczÄcy ochrony prywatnoÅci.',
        'ita': 'Ã necessario accettare il testo sulla privacy',
        'bul': 'Ð¢ÑÑÐ±Ð²Ð° Ð´Ð° ÑÐµ ÑÑÐ³Ð»Ð°ÑÐ¸ÑÐµ Ñ ÑÐµÐºÑÑÐ° Ð·Ð° Ð¿Ð¾Ð²ÐµÑÐ¸ÑÐµÐ»Ð½Ð¾ÑÑ',
        'ron': 'Trebuie sÄ fiÈi de acord cu textul privind confidenÈialitatea.',
        'dan': 'Du skal acceptere privatlivspolitikken.',
    },
    'testPage.notAllQuestionsAnswered': {
        'de': 'Sie mÃ¼ssen alle Fragen beantworten.',
        'en': 'You need to answer all questions.',
        'pl': 'Musisz odpowiedzieÄ na wszystkie pytania.',
        'ita': 'Ã necessario rispondere a tutte le domande.',
        'bul': 'Ð¢ÑÑÐ±Ð²Ð° Ð´Ð° Ð¾ÑÐ³Ð¾Ð²Ð¾ÑÐ¸ÑÐµ Ð½Ð° Ð²ÑÐ¸ÑÐºÐ¸ Ð²ÑÐ¿ÑÐ¾ÑÐ¸.',
        'ron': 'Trebuie sÄ rÄspundeÈi la toate Ã®ntrebÄrile.',
        'dan': 'Du skal besvare alle spÃ¸rgsmÃ¥l.',
    },
    'testPage.clearAnswers': {
        'de': 'Antworten zurÃ¼cksetzten',
        'en': 'Reset answers',
        'pl': 'Zresetuj odpowiedzi',
        'ita': 'Reimposta le risposte',
        'bul': 'ÐÑÐ»Ð¸ÑÐ°Ð½Ðµ Ð½Ð° Ð¾ÑÐ³Ð¾Ð²Ð¾ÑÐ¸ÑÐµ',
        'ron': 'ResetaÈi rÄspunsurile',
        'dan': 'Nulstil svar',
    },
    'testPage.alreadyAnswers': {
        'de': 'Sie haben bereits vorausgewÃ¤hlte Antworten, wenn Sie diese lÃ¶schen mÃ¶chten klicken Sie ',
        'en': 'You have already preselected answers, if you want to delete them click ',
        'pl': 'Masz juÅ¼ wstÄpnie wybrane odpowiedzi, jeÅli chcesz je usunÄÄ, kliknij przycisk ',
        'ita': 'Le risposte sono giÃ  state preselezionate, se si desidera eliminarle fare clic su ',
        'bul': 'ÐÐµÑÐµ Ð¸Ð¼Ð°ÑÐµ Ð¿ÑÐµÐ´Ð²Ð°ÑÐ¸ÑÐµÐ»Ð½Ð¾ Ð¸Ð·Ð±ÑÐ°Ð½Ð¸ Ð¾ÑÐ³Ð¾Ð²Ð¾ÑÐ¸, Ð°ÐºÐ¾ Ð¸ÑÐºÐ°ÑÐµ Ð´Ð° Ð³Ð¸ Ð¸Ð·ÑÑÐ¸ÐµÑÐµ, ÑÑÐ°ÐºÐ½ÐµÑÐµ Ð²ÑÑÑÑ ',
        'ron': 'AveÈi deja rÄspunsuri preselectate, dacÄ doriÈi sÄ le ÈtergeÈi faceÈi clic pe ',
        'dan': 'Du har allerede forudvalgte svar, hvis du vil slette dem, klik ',
    },
    'testPage.alreadyAnswersButtonText': {
        'de': 'hier',
        'en': 'here',
        'pl': 'tutaj',
        'ita': 'qui',
        'bul': 'ÑÑÐº',
        'ron': 'aici',
        'dan': 'her',
    },
    'testPage.dataTracking': {
        'de': 'Ich bestÃ¤tige hiermit, dass ich die oben angegebene Person bin und ich diese Fragen selbststÃ¤ndig und ohne fremde Hilfe ausgefÃ¼llt habe. Identifizierende Daten wie IP- Adresse, benutzter Browser, Ort der AusfÃ¼hrung, der Start sowieso das Absenden des Tests mit meinen Antworten gespeichert und zum Zweck der Auswertung und Validierung des Fragebogens genutzt werden darf. Weitere Informationen kÃ¶nnen Sie bei Ihrem/Ihrer Sicherheitsbeauftragten anfragen.',
        'en': 'I hereby confirm that I am the person indicated above and that I have completed these questions independently and without outside assistance. Identifying data such as IP address, browser used, place of execution, the start anyway the submission of the test with my answers may be stored and used for the purpose of evaluation and validation of the questionnaire. For more information, please contact your security officer.',
        'pl': 'Niniejszym potwierdzam, Å¼e jestem osobÄ wskazanÄ powyÅ¼ej oraz Å¼e wypeÅniÅem/am te pytania samodzielnie i bez pomocy osÃ³b trzecich. Niniejszym potwierdzam, Å¼e dane identyfikacyjne, takie jak adres IP, uÅ¼ywana przeglÄdarka, miejsce wykonania, rozpoczÄcie i wysÅanie testu z moimi odpowiedziami mogÄ byÄ przechowywane i wykorzystywane w celu oceny i walidacji kwestionariusza. Aby uzyskaÄ wiÄcej informacji, naleÅ¼y skontaktowaÄ siÄ z pracownikiem ochrony.',
        'ita': 'Con la presente confermo di essere la persona sopra indicata e di aver compilato queste domande in modo autonomo e senza alcun aiuto esterno. Confermo che i dati identificativi come l\'indirizzo IP, il browser utilizzato, il luogo di esecuzione, l\'inizio e l\'invio del test con le mie risposte possono essere memorizzati e utilizzati ai fini della valutazione e della convalida del questionario.Per ulteriori informazioni, contattare l\'addetto alla sicurezza.',
        'bul': 'Ð¡ Ð½Ð°ÑÑÐ¾ÑÑÐ¾ÑÐ¾ Ð¿Ð¾ÑÐ²ÑÑÐ¶Ð´Ð°Ð²Ð°Ð¼, ÑÐµ Ð°Ð· ÑÑÐ¼ Ð»Ð¸ÑÐµÑÐ¾, Ð¿Ð¾ÑÐ¾ÑÐµÐ½Ð¾ Ð¿Ð¾-Ð³Ð¾ÑÐµ, Ð¸ ÑÐµ ÑÑÐ¼ Ð¿Ð¾Ð¿ÑÐ»Ð½Ð¸Ð» ÑÐµÐ·Ð¸ Ð²ÑÐ¿ÑÐ¾ÑÐ¸ ÑÐ°Ð¼Ð¾ÑÑÐ¾ÑÑÐµÐ»Ð½Ð¾ Ð¸ Ð±ÐµÐ· Ð²ÑÐ½ÑÐ½Ð° Ð¿Ð¾Ð¼Ð¾Ñ. Ð¡ Ð½Ð°ÑÑÐ¾ÑÑÐ¾ÑÐ¾ Ð¿Ð¾ÑÐ²ÑÑÐ¶Ð´Ð°Ð²Ð°Ð¼, ÑÐµ Ð¸Ð´ÐµÐ½ÑÐ¸ÑÐ¸ÐºÐ°ÑÐ¸Ð¾Ð½Ð½Ð¸ÑÐµ Ð´Ð°Ð½Ð½Ð¸, ÐºÐ°ÑÐ¾ IP Ð°Ð´ÑÐµÑ, Ð¸Ð·Ð¿Ð¾Ð»Ð·Ð²Ð°Ð½ Ð±ÑÐ°ÑÐ·ÑÑ, Ð¼ÑÑÑÐ¾ Ð½Ð° Ð¸Ð·Ð¿ÑÐ»Ð½ÐµÐ½Ð¸Ðµ, Ð½Ð°ÑÐ°Ð»Ð¾ Ð¸ Ð¿Ð¾Ð´Ð°Ð²Ð°Ð½Ðµ Ð½Ð° ÑÐµÑÑÐ° Ñ Ð¼Ð¾Ð¸ÑÐµ Ð¾ÑÐ³Ð¾Ð²Ð¾ÑÐ¸, Ð¼Ð¾Ð³Ð°Ñ Ð´Ð° Ð±ÑÐ´Ð°Ñ ÑÑÑÑÐ°Ð½ÑÐ²Ð°Ð½Ð¸ Ð¸ Ð¸Ð·Ð¿Ð¾Ð»Ð·Ð²Ð°Ð½Ð¸ Ð·Ð° ÑÐµÐ»Ð¸ÑÐµ Ð½Ð° Ð¾ÑÐµÐ½ÑÐ²Ð°Ð½ÐµÑÐ¾ Ð¸ Ð²Ð°Ð»Ð¸Ð´Ð¸ÑÐ°Ð½ÐµÑÐ¾ Ð½Ð° Ð²ÑÐ¿ÑÐ¾ÑÐ½Ð¸ÐºÐ°. ÐÐ° Ð´Ð¾Ð¿ÑÐ»Ð½Ð¸ÑÐµÐ»Ð½Ð° Ð¸Ð½ÑÐ¾ÑÐ¼Ð°ÑÐ¸Ñ ÑÐµ Ð¾Ð±ÑÑÐ½ÐµÑÐµ ÐºÑÐ¼ Ð²Ð°ÑÐ¸Ñ ÑÐ»ÑÐ¶Ð¸ÑÐµÐ» Ð¿Ð¾ ÑÐ¸Ð³ÑÑÐ½Ð¾ÑÑÑÐ°.',
        'ron': 'Confirm prin prezenta cÄ sunt persoana indicatÄ mai sus Èi cÄ am completat aceste Ã®ntrebÄri Ã®n mod independent Èi fÄrÄ ajutor extern. Confirm prin prezenta cÄ datele de identificare, cum ar fi adresa IP, browserul utilizat, locul de execuÈie, Ã®nceputul Èi trimiterea testului cu rÄspunsurile mele pot fi stocate Èi utilizate Ã®n scopul evaluÄrii Èi validÄrii chestionarului. Pentru informaÈii suplimentare, vÄ rugÄm sÄ vÄ adresaÈi ofiÈerului de securitate.',
        'dan': 'Jeg bekrÃ¦fter hermed, at jeg er ovennÃ¦vnte person, og at jeg har besvaret disse spÃ¸rgsmÃ¥l selvstÃ¦ndigt og uden hjÃ¦lp udefra. Identificerende data som IP-adresse, anvendt browser, udfÃ¸relsessted, start og afsendelse af testen med mine svar kan gemmes og bruges med det formÃ¥l at evaluere og validere spÃ¸rgeskemaet. Du kan anmode om yderligere oplysninger fra din sikkerhedsofficer.',
    },
    'testPage.submit': {
        'de': 'Absenden',
        'en': 'Submit',
        'pl': 'SkÅadaÄ',
        'ita': 'Invia',
        'bul': 'ÐÐ·Ð¿ÑÐ°ÑÐ°Ð½Ðµ',
        'ron': 'Trimite',
        'dan': 'Indsend',
    },

    // #endregion

    // #region Test submitted page

    'result.text': {
        'de': 'Das ist Ihr Ergebnis',
        'en': 'This is your result',
        'pl': 'To jest TwÃ³j wynik',
        'ita': 'Questo Ã¨ il risultato',
        'bul': 'Ð¢Ð¾Ð²Ð° Ðµ Ð²Ð°ÑÐ¸ÑÑ ÑÐµÐ·ÑÐ»ÑÐ°Ñ',
        'ron': 'Acesta este rezultatul dvs',
        'dan': 'Dette er dit resultat',
    },
    'result.success': {
        'de': 'Bestanden',
        'en': 'Success',
        'pl': 'Powodzenie',
        'ita': 'Successo',
        'bul': 'ÑÑÐ¿ÐµÑ',
        'ron': 'Succes',
        'dan': 'BestÃ¥et',
    },
    'result.failed': {
        'de': 'Durchgefallen',
        'en': 'Failed',
        'pl': 'Przegrany',
        'ita': 'Fallito',
        'bul': 'ÑÐµ Ð¿ÑÐ¾Ð²Ð°Ð»Ð¸',
        'ron': 'A eÈuat',
        'dan': 'Mislykkedes',
    },
    'result.correctAnswers': {
        'de': 'Sie haben {0}/{1} richtige Antworten gehabt.',
        'en': 'You had {0}/{1} correct answers.',
        'pl': 'MiaÅeÅ {0}/{1} poprawnych odpowiedzi.',
        'ita': 'Le risposte corrette erano {0}/{1}.',
        'bul': 'ÐÐ¼Ð°ÑÐµ {0}/{1} Ð²ÐµÑÐ½Ð¸ Ð¾ÑÐ³Ð¾Ð²Ð¾ÑÐ¸.',
        'ron': 'AÈi avut {0}/{1} rÄspunsuri corecte.',
        'dan': 'Du havde {0}/{1} rigtige svar.',
    }

    // #endregion
});

// Load language from localStorage
let startLanguage = localStorage.getItem('language');
if (!startLanguage) startLanguage = 'de';

// First Layer of the Webapplication
export const Application: React.FC<IProps> = (props) => {

    //return (<_Example />)

    return (
        <GlobalEventStoreProvider
            store={store}>
            <LanguageContextProvider
                language={startLanguage}
                allLanguages={allLanguages}
                onLanguageChanged={_ => localStorage.setItem('language', _)}
            >
                <TranslationContextProvider
                    id={'global'}
                    translations={translations.entries}
                >
                    <div className="page-wrap">
                        <RootPage />
                    </div>
                    <div className="site-footer">
                        Â© 2016 - 2022 <a className='link' href={'https://www.freemind-it.de/de'} target={'_blank'}>Free Mind IT GmbH</a> | <a className='link' href={'https://www.freemind-it.de/de/Impressum'} target={'_blank'}>Impressum</a>
                    </div>
                </TranslationContextProvider >
            </LanguageContextProvider>
        </GlobalEventStoreProvider>
    )
}